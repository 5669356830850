/* Layout
/////////////////////// */
html {
    overflow-y: scroll;
}

body {
    font-size: 8pt;
    color: #000000;
    font-family: Verdana, Arial;
    background-color: #e4e4e4;
}

/**
 * Embed fix
 */
.embed-wrapper {
    position: relative;
    z-index: 0;
}

h1 {
    margin-top: 0;
}

.navbar {
    border: none;

    a {
        
    }

}

.navbar-nav {
    margin: 0;
}

.container {
    width: auto;

    &.xs-main-container {
        width: 768px;
        /*max-width: 917px;*/
        background-color: #e4e4e4;
        overflow: auto;
        display: table;
    }
}

sup {
    font-size: 0.5em;
}


/* Header Styles
/////////////////////// */
@import 'header';

/* Navbar (Primary nav) Styles
/////////////////////// */
@import 'navbar';


/* Main Content Styles
/////////////////////// */
@import 'main';


/* Sidebar Styles
/////////////////////// */
@import 'sidebar';

/* Footer Styles
/////////////////////// */
@import 'footer';
