/* Sidebar Styles */
@media (min-width: 0px) {
    .xs-sidebar {   
        padding: 0;
        vertical-align: top;
        box-shadow: 0px 0px 10px 0px rgba(black, 0.20);
        white-space: nowrap;

        &.show {
            height: auto;
        }

        /*Desktop Styling*/
            height: auto;
            padding: 0;
            border-radius: 4px;
    }


    .xs-profile-picture {
        display: none;

        padding: 19px 19px 7px 19px;


        img {
            height: auto;
            max-width: 100%;
            border: 1px solid black;
        }
    }


    #xs-sidebar {
        padding: 0;

        /* Specific width of sidebar */
        width: 160px;
        margin-right: 5px;

        .xs-secondary-navigation {
            margin: 1em 10px;


            li {
                border: 1px solid rgba(black, 0.55);

                a {
                    box-shadow: inset 0px -24px 30px -12px rgba(black, 0.30);
                }
            }
        }

        .xs-alt-navigation {
            margin: 1em 10px;
        }

        li {
            
            display: block;
            position: relative;
            width: 140px;

            &.spacer {
                background: transparent;
                height: 24px;
                border: none;

                &.first {
                    border-top: 1px solid rgba(black, 0.25);
                }
            }

            /* indented items */
            &.indent > a {
                padding-left: 16px;
            }

            /* has sub-nav child */
            &.has-children {
                /* right arrow indicator */
                svg.sub-indicator {
                    opacity: 0.25;
                    position: absolute;
                    top: 6px;
                    right: 3px;
                }
            }

            /* 'fly-out' sub-navigation */
            .sub-nav {
                border-bottom: 1px solid rgba(black, 0.25);
                display: none;
                left: 138px;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0;
                width: 142px;
                z-index: 101;

                li {
                    a {
                        padding: 6px;

                        &:hover {
                            text-decoration: none;
                            background-color: transparent;
                        }
                    }

                    &.indent {
                        a {
                            padding-left: 12px;
                        }
                    }
                }
            }

            /* on li:hover
            SHOW .sub-nav */
            &:hover {
                .sub-nav {
                    display: block;
                }
            }

            a {
                display: block;
                padding: 2px 6px;
                transition: background .4s;
                overflow: hidden;

                svg {
                    margin: 0 6px 0 0;
                }

                &:hover {
                    background-color: transparent;
                    box-shadow: inset 0 24px 8px -12px rgba(black, 0.10);
                }

            }
        }

        /* xs-alt-navigation links */
        .xs-alt-navigation {
            li {
                background-color: transparent;
                margin-bottom: 1em;

                a {
                    text-indent: -999em;
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: center;
                    min-height: 31px;
                    width: 140px;
                    /* "Tell a Friend */
                    &[href="/TellaFriend"], &[href="/tellfriend.x"] {
                        background-image: url(/images/tellafriend.png);
                    }
                    
                    /* "Adobe Acrobat */
                    &[href="/DownloadAdobeAcrobat"], &[href="/downloadadobe.x"] {
                        background-image: url(/images/acrobat.png);
                    }
                }
            }
        }/* /.xs-alt-navigation */

        

        /* Language options */
       .xs-language-options {
           background: rgba(white,0.375);
           border: 1px solid rgba(black,0.5);
           font-size: 0.8em;
           margin: 7px 1em 1em 1em;
           padding: 0.5em 0;

           label {
               margin-bottom: 0;
           }

           a {
               color: #337ab7 ;
               font-family: initial;
               font-size: 9.6px;
               font-style: normal;
               font-weight: normal;
               line-height: 13.7143px;
               text-align: center;
               text-decoration: underline;

               &::after {
                   content: "|";
                   display: inline-block;
                   margin: 0 0.25em 0 0.5em;
                   text-decoration: none;
               }

               &:last-child {
                   &::after {
                       display: none;
                   }
               }
           }

       } /* /.xs-language-options */

    }   
}

