/* Header Styles */
@media (min-width: 0px) {
	header {
		overflow: hidden;

		/*Desktop Styling*/
			margin: 3px 3px 10px 3px;
			border-radius: 4px;
			/* Specific height of header */
			width: 767px;
			position: relative;
			box-shadow: 0px 0px 10px 0px rgba(black, 0.20);

		.header-image {
			/* Specific height of header and margin */
			/*Desktop Styling*/
				margin:  10px;
				height: 168px;
				width: 747px;
				border-right: 1px solid rgba(black, 0.15);
				border-left: 1px solid rgba(black, 0.15);
				border-top: 1px solid rgba(black, 0.15);
				position: relative;
				overflow: hidden;
				background-color: #e4e4e4;
		}
	}

	.company-logo {
		position: absolute;
		display: none;
	   
		img {
			height: auto;
			width: auto;
		}
	}

	.company-name {
		line-height: 1;
		position: absolute;
		text-decoration: none;
		
		&:hover {
			text-decoration: none;
		}
	}

	.company-motto {
		line-height: 1;
		position: absolute;

	}
}