/* Primary navigation */
@media (min-width: 0px) {
	#xs-navbar-collapse {

		/*Desktop Styling*/
			margin-bottom: 5px;
			padding: 0;
			display: block;
			width: 747px;
			margin-right: 10px;
			margin-left: 10px;
			border-radius: 0;
			white-space: nowrap;


		.xs-primary-navigation {
			width: 100%;

			li {
				float: left;
				/*Desktop Styling*/

					border: 1px solid rgba(black, 0.25);
					margin: 1px;
					box-shadow: inset 0px -24px 9px -12px rgba(black, 0.20);

					text-align: center;
					width: 16.444444445%;

					a {
						overflow: hidden;
					}

					&.spacer {
						display: block;
						height: 28px;
						width: 16.444444445%;
						background-color: transparent;
						border: none;
						box-shadow: none;
					}

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}

					a:hover {
						box-shadow: inset 0 20px 10px -10px rgba(black, 0.10);
						background-color: transparent;
						padding-bottom: 1px;
					}
			}
		}
	} /* /#xs-navbar-collapse */

	.navbar {
		/* Specific height of main nav */
		/*Desktop Styling*/
			min-height: 30px;
	}
	.navbar-nav>li>a {
		/*Desktop Styling*/
			padding: 3px 0;	
			/*font-size: 10px;*/
	}
}