/* Main Content Styles */
@media (min-width: 0px){
    main {
        border-radius: 4px;
        display: table;
    }

    /* Specific width of main content to account for sidebar width */
    .xs-main-content {
        width: 602px;
        display: table-cell;
        float: none;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 0px rgba(black, 0.20);
        vertical-align: top;
    }
}